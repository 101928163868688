<template>
  <section
    v-navItemTrigger
    id="inspirations"
    class="w-full relative py-20 px-10 md:py-30 md:px-36 lg:px-64"
  >
    <div class="w-full md:w-9/12 h-auto mx-auto relative text-left">
      <div>
        <h4 class="text-7xl font-bold md:w-1/2 text-center">
          Be <br />
          Inspired
        </h4>
        <p class="capitalize text-xl mt-4 md:w-1/2 text-center">
          With some of our proud work
        </p>
      </div>

      <br />
      <div >
        <div class="bg-gray-700 md:w-3/4 h-96 shadow-sm">
          <!-- <video src="https://www.futurecreations.site/res/insipirations/WiseAppFinall.mp4" class="h-full md:h-auto md:w-full object-cover" autoplay='false' controls></video> -->
          <img src="https://www.futurecreations.site/res/inspirations/WiseAppBuyer.png" alt="" class="h-full w-full object-cover">
        </div>
      </div>

      <br />
      <p class="md:w-1/5 text-justify">
        We do our best to pay attention to your needs and deliver accordingly
        with utmost <span class="font-bold text-xl">Excellency</span>
      </p>

      <br />
      <div
        class="md:w-2/5 bg-gray-500 md:absolute top-5 right-10 shadow-sm"
        style="height: auto;"   
      >
        <img src="https://www.futurecreations.site/res/inspirations/passion.jpg" alt="" class="w-full transform translate-x- md:translate-x-0 md:h-full md:w-full object-cover">
      </div>

      <br />
      <div
        class="md:w-1/3 bg-gray-100 md:absolute left-80 shadow-sm"
        style="height: auto; top: 70%"
        
      >
        <img src="https://www.futurecreations.site/res/inspirations/studyabroad.png" alt="" class="object-cover">
      </div>

      <!-- <br />
      <div
        class="md:w-1/4 bg-gray-300 md:absolute"
        style="height: 250px; top: 90%; left: 60%;"
        
      ></div> -->
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (min-width: 768px) {
    section {
        height: 1200px;
    }
}
</style>