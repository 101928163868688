<template>
  <section v-navShadowTrigger v-navItemTrigger  id="home" class="w-full h-screen flex flex-col md:flex-row justify-center md:justify-between items-center transition-opacity">
      <div class="w-full block md:hidden">
          <img src="@/assets/illustrations/19362653.jpg" alt="">
          <br>
      </div>

      <div class="w-full md:w-1/2 px-10 md:px-36 lg:px-64 text-center md:text-left">
          <h1 class="uppercase font-black primary-text-color text-6xl md:text-8xl lg:text-9xl">future</h1>
          <h2 class="uppercase text-gray-800 text-4xl md:text-7xl lg:text-8xl font-light">cr<b class="font-medium">eatio</b>ns</h2>
          <p class="md:text-xl text-center md:text-justify mt-5 md:mt-3">
              We make it our desire to bring you nothing but excellency
          </p>

          <br>
          <div class="mt-5 md:mt-0">
              <a href="#services" class="capitalize font-medium px-4 py-2 rounded-full md:rounded-md transition-colors">what we do</a>
          </div>
      </div>

      <div class="w-full md:w-1/2 hidden md:block">
          <img src="@/assets/illustrations/19362653.jpg" alt="">
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
h1, h2 {
    letter-spacing: 8px;
}

a {
    border: 2px solid rgb(3, 86, 119);
    color: rgb(3, 86, 119);
    /* transition: background-color, .3s, cubic-bezier(0.075, 0.82, 0.165, 1); */
}

a:hover {
    background-color: rgb(3, 86, 119);
    color: #fff;
}
</style>