<template>
  <section
    v-navItemTrigger
    
    id="about"
    class="w-full flex flex-col md:flex-row justify-between items-center py-10"
  >
    <div class="w-full md:w-2/5">
      <img src="@/assets/illustrations/3847762.jpg" alt="" />
    </div>

    <div class="w-full md:w-3/5 lg:w-1/2 px-10 md:px-36 lg:px-64 text-center mt-5 md:mt-0">
      <h4 class="text-4xl">About <span class="font-bold">Us</span></h4>

      <br />
      <p class="text-center">
        Future Creations is a Malawian startup company based in Lilongwe that
        aims at offering high quality modern services with maximum customer
        satisfaction achieved through customer centered designs.
      </p>

      <br />
      <h5 class="text-2xl">Our <span class="font-bold">Core</span> Values</h5>

      <br>
      <ul class="md:flex justify-between">
        <li v-for="(value, i) in values" :key="i" class="bg-gray-100 px-4 py-2 rounded-full capitalize font-semibold mt-3 md:mt-0">
          {{ value }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const values = ["professional", "panctual", "devoted"];

    return { values };
  },
};
</script>

<style>
</style>