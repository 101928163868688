<template>
  <!-- Navigation -->
  <Navigation />

  <!-- Landing section -->
  <Landing />

  <!-- About section -->
  <About />

  <!-- Services section -->
  <Services />

  <!-- Inspirations section -->
  <Inspirations />

  <!-- Contacts -->
  <Contacts />
</template>

<script>
import Landing from '../components/Landing.vue'
import Navigation from '../components/Navigation.vue'
import About from '../components/About.vue'
import Services from '../components/Services.vue'
import Inspirations from '../components/Inspirations.vue'
import Contacts from '../components/Contacts.vue'

export default {
  components: { Navigation, Landing, About, Services, Inspirations, Contacts },
  name: 'Home'
}
</script>
