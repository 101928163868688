<template>
  <section v-navItemTrigger id="services" class="w-full primary-bg-color">
    <div  class="text-center py-10 px-10 md:px-36 lg:px-64">
      <h4 class="text-3xl text-white">Our Services</h4>

      <br />
      <p class="text-white">
        We work hard to bring you unique to you services and or products with
        the ultimate quality.
      </p>

      <br />
      <div class="flex flex-col md:flex-row justify-between w-full py-8">
        <div
          class="mx-5 my-7 md:mt-0"
          v-for="(service, i) in services"
          :key="i"
        >
          <i
            :class="`fa-solid fa-${service.icon} text-5xl md:text-7xl text-white`"
          ></i>

          <br />
          <br />
          <h5 class="text-2xl md:text-4xl text-white">
            {{ service.name }}
          </h5>

          <br />
          <p class="text-gray-50">
            {{ service.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <div
    class="parallax flex items-center justify-center px-10 md:px-36 lg:px-64"
  >
    <p  class="text-2xl">WE BELIEVE IN MAKING OUR CUSTOMERS HAPPY.</p>
  </div>
</template>

<script>
export default {
  setup() {
    const services = [
      {
        name: "Web Applications Development",
        description: "Modern and user friendly responsive web applications",
        icon: "laptop",
      },
      {
        name: "Mobile Applications Development",
        description: "Intuitive cross platform mobile applications",
        icon: "mobile",
      },
      {
        name: "Motion Graphics Designing",
        description: "High quality trending motion graphics designs",
        icon: "clapperboard",
      },
    ];

    return { services };
  },
};
</script>

<style>
.parallax {
  background-image: url("../assets/illustrations/17973908.jpg");

  min-height: 250px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>