import { createApp } from "vue";
import App from "./App.vue";
// import opacityTrigger from './directives/Animation/opacityTrigger'
import navItemTrigger from "./directives/Navigation/navItemTrigger";
import navShadowTrigger from "./directives/Navigation/navShadowTrigger";
import router from "./router";
import "./styles/app.css";

createApp(App)
    .use(router)
    .directive("navItemTrigger", navItemTrigger)
    .directive("navShadowTrigger", navShadowTrigger)
    .mount("#app");