<template>
  <router-view/>
</template>

<style>
* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  scroll-behavior: smooth;
}

#app {
  position: relative;
}

.primary-text-color {
  color: rgb(4, 117, 161);
}

.primary-bg-color {
  background-color: rgb(4, 117, 161);
}

.section-in {
  animation: fade-in .3s ease-in;
}

.section-out {
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  25% {
    opacity: 25%;
  } 
  50% {
    opacity: 50%;
  }
  75% {
    opacity: 75%;
  } 
  100% {
    opacity: 100%;
  }
}
</style>
